import React from 'react'
import Grid from '@/components/Grid'
import {
  ContentButtonItem,
  ContentImageItem,
  ContentRow,
  ContentTextItem,
} from '@/types/Content'
import elementItemHelper from '@/helpers/elementItemHelper'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import {
  FONT_16,
  FONT_22,
  FONT_18_EXTRA_BOLD,
  FONT_22_EXTRA_BOLD,
  FONT_30_EXTRA_BOLD,
  FONT_50_EXTRA_BOLD,
  STYLE_FONT_FAMILY,
  STYLE_KEEP_WORD,
  FONT_18,
} from '@/styles/fonts'
import {
  STYLE_ARROW_BUTTON_BLACK,
  STYLE_OUTLINE_BUTTON,
} from '@/styles/buttons'
import TransitionHandler from '@/components/TransitionHandler'
import { EffectType } from '@/enums/Content'
import AutoLinkText from '@/components/AutoLinkText'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

interface Grid1CenterAlignedTextProps {
  item: ContentRow
}

const Partial = {
  Image({ image }: { image: ContentImageItem[] }) {
    if (!image) return null
    return (
      <>
        {image?.map(({ src, alt, show, width, height }, index) => (
          <TransitionHandler
            effect={EffectType.ZOOM}
            key={`Grid1CenterAlignedText-image-${index}`}
            speed={0.6}
          >
            <DisplayConfigWrapper show={show}>
              <Styled.Image
                src={src}
                alt={alt || ''}
                width={width}
                height={height}
              />
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Title({ title }: { title: ContentTextItem[] }) {
    if (!title) return null
    return (
      <>
        {title?.map(({ value, show, styleType = 'type1', as }, index) => (
          <TransitionHandler key={`Grid1CenterAlignedText-title-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.Title as={as} styleType={styleType}>
                {value}
              </Styled.Title>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) return null
    return (
      <>
        {text?.map(({ value, show, styleType = 'type1' }, index) => (
          <TransitionHandler key={`Grid1CenterAlignedText-text-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.Text styleType={styleType}>{value}</Styled.Text>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Button({ button }: { button: ContentButtonItem[] }) {
    if (!button) return null
    return (
      <>
        <Styled.ButtonWrap firstButtonStyle={button[0]?.buttonStyle}>
          {button?.map((item, index) => (
            <TransitionHandler key={`Grid1CenterAlignedText-button-${index}`}>
              <DisplayConfigWrapper show={item?.show}>
                <Styled.Button {...item}>{item?.label}</Styled.Button>
              </DisplayConfigWrapper>
            </TransitionHandler>
          ))}
        </Styled.ButtonWrap>
      </>
    )
  },
}

function Grid1CenterAlignedText({ item }: Grid1CenterAlignedTextProps) {
  const { columns, type } = item
  const [{ items }] = columns ?? [{}]
  const { title, text, button, image } = elementItemHelper(items)

  return (
    <Grid gridType={type}>
      <Styled.Wrap>
        <Partial.Image image={image} />
        <Partial.Title title={title} />
        <Partial.Text text={text} />
        <Partial.Button button={button} />
      </Styled.Wrap>
    </Grid>
  )
}

const Styled = {
  Wrap: styled.div`
    font-family: ${STYLE_FONT_FAMILY.KAKAO_BIG};
    text-align: center;
  `,
  Image: styled.img`
    padding-bottom: 30px;
  `,
  Title: styled.div<{ styleType?: string }>`
    ${STYLE_KEEP_WORD};
    white-space: pre-wrap;
    ${({ styleType }) =>
      styleType === 'type1' &&
      css`
        ${FONT_30_EXTRA_BOLD};
      `}
    ${({ styleType }) =>
      styleType === 'type2' &&
      css`
        ${FONT_50_EXTRA_BOLD};
      `}
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_22_EXTRA_BOLD};
    }
  `,
  Text: styled.p<{ styleType?: string }>`
    ${STYLE_KEEP_WORD};
    margin-top: 40px;
    white-space: pre-wrap;
    ${({ styleType }) =>
      styleType === 'type1' &&
      css`
        ${FONT_22_EXTRA_BOLD};
      `}
    ${({ styleType }) =>
      styleType === 'type2' &&
      css`
        ${FONT_22};
        color: ${STYLE_COLOR.BLACK02};
      `}

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding: 0 5%;
      margin-top: 16px;
      ${({ styleType }) =>
        styleType === 'type1' &&
        css`
          ${FONT_18_EXTRA_BOLD};
        `}
      ${({ styleType }) =>
        styleType === 'type2' &&
        css`
          ${FONT_16};
          color: ${STYLE_COLOR.BLACK02};
        `}
    }
  `,
  ButtonWrap: styled.div<{
    firstButtonStyle?: ContentButtonItem['buttonStyle']
  }>`
    display: flex;
    justify-content: center;
    & > *:not(:first-of-type) {
      margin-left: 10px;
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      flex-direction: column;
      & > *:not(:first-of-type) {
        margin: 0;
        margin-top: 10px;
      }
    }

    ${({ firstButtonStyle }) => {
      switch (firstButtonStyle) {
        case 'outline':
          return css`
            margin-top: 40px;
            @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
              margin-top: 16px;
            }
          `
        case 'button':
        default:
          return css`
            margin-top: 30px;
            @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
              margin-top: 12px;
            }
          `
      }
    }}
  `,
  Button: styled(AutoLinkText)<{
    buttonStyle?: ContentButtonItem['buttonStyle']
  }>`
    ${({ buttonStyle }) => {
      switch (buttonStyle) {
        case 'outline':
          return css`
            ${STYLE_OUTLINE_BUTTON};
            ${FONT_22};
            display: inline-flex;
            align-items: center;
            justify-content: center;
            min-width: 240px;
            min-height: 86px;
            margin-top: 10px;

            &:hover {
              background-color: ${STYLE_COLOR.YELLOW};
              border: 1px solid ${STYLE_COLOR.YELLOW};
            }

            @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
              margin: 0;
              ${FONT_18};
              min-width: 200px;
              min-height: 56px;
            }
          `
        default:
          return STYLE_ARROW_BUTTON_BLACK
      }
    }};
  `,
}

export default Grid1CenterAlignedText
