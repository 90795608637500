import React, { PropsWithChildren } from 'react'
import {
  ContentBackgroundItem,
  ContentButtonItem,
  ContentImageItem,
  ContentRow,
  ContentTextItem,
} from '@/types/Content'
import { EffectType, ImageFitType } from '@/enums/Content'
import styled from '@emotion/styled'
import {
  FONT_16,
  FONT_22_EXTRA_BOLD,
  FONT_30_EXTRA_BOLD,
  STYLE_KEEP_WORD,
} from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import {
  STYLE_ARROW_BUTTON_BLACK,
  STYLE_ARROW_BUTTON_WHITE,
} from '@/styles/buttons'
import TransitionHandler from '@/components/TransitionHandler'
import AutoLinkText from '@/components/AutoLinkText'
import elementItemHelper from '@/helpers/elementItemHelper'
import Grid from '@/components/Grid'

interface Grid1FullImageProps {
  item: ContentRow
}

const Partial = {
  Image({ image }: { image: ContentImageItem[] }) {
    if (!image) return null
    return (
      <>
        {image?.map((item, index) => (
          <DisplayConfigWrapper
            show={item?.show}
            key={`Grid1FullImage-image-${index}`}
          >
            <Styled.Image {...item} alt={item.alt || ''} />
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  BackgroundImage({ image }: { image: ContentBackgroundItem[] }) {
    if (!image) return null
    return (
      <>
        {image?.map((item, index) => (
          <TransitionHandler
            key={`Grid1FullImage-backgroundImage-${index}`}
            effect={item?.effectType ?? EffectType.FADE_UP}
          >
            <DisplayConfigWrapper show={item?.show}>
              <Styled.BackgroundImage {...item} />
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Title({ title }: { title: ContentTextItem[] }) {
    if (!title) return null
    return (
      <>
        {title?.map(({ value, show, as }, index) => (
          <TransitionHandler key={`Grid1FullImage-title-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.Title as={as}>{value}</Styled.Title>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) return null
    return (
      <>
        {text?.map((item, index) => (
          <TransitionHandler key={`Grid1FullImage-text-${index}`}>
            <DisplayConfigWrapper show={item?.show}>
              <Styled.Text {...item}>{item?.value}</Styled.Text>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Button({ button }: { button: ContentButtonItem[] }) {
    if (!button) return null
    return (
      <>
        {button?.map((item, index) => (
          <TransitionHandler
            key={`Grid1FullImage-button-${index}`}
            effect={EffectType.FADE_SHORT_RIGHT}
            speed={0.6}
          >
            <DisplayConfigWrapper show={item?.show}>
              <Styled.Button {...item}>{item?.label}</Styled.Button>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
}

function Grid1FullImage({ item }: PropsWithChildren<Grid1FullImageProps>) {
  const { columns } = item
  const [{ items }] = columns ?? [{}]
  const { title, image, backgroundImage, text, button } =
    elementItemHelper(items)
  const key = 'Grid1FullImage'

  return (
    <Styled.Wrap key={key}>
      <Styled.MediaWrap>
        <Partial.Image image={image} />
        <Partial.BackgroundImage image={backgroundImage} />
      </Styled.MediaWrap>
      <Styled.TextWrap>
        <Grid spacing>
          <>
            <Partial.Title title={title} />
            <Partial.Text text={text} />
            <Partial.Button button={button} />
          </>
        </Grid>
      </Styled.TextWrap>
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
  `,
  MediaWrap: styled.div<{ height?: number | string }>`
    max-height: ${({ height }) =>
      typeof height === 'number' ? `${height}px` : height};
  `,
  Image: styled.img`
    width: 100%;
  `,
  BackgroundImage: styled.div<{
    height?: number | string
    fit?: ImageFitType
    src?: string
  }>`
    width: 100%;
    height: ${({ height }) => height && `${height}px`};
    background-size: ${({ fit }) =>
      fit === ImageFitType.CONTAIN ? ImageFitType.CONTAIN : ImageFitType.COVER};
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${({ src }) => src});
  `,
  TextWrap: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 120px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      position: initial;
      padding-top: 24px;
      text-align: center;
    }
  `,
  Title: styled.div`
    ${FONT_30_EXTRA_BOLD};
    color: ${STYLE_COLOR.WHITE01};
    ${STYLE_KEEP_WORD};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_22_EXTRA_BOLD};
      color: ${STYLE_COLOR.BLACK01};
    }
  `,
  Text: styled.p<ContentTextItem>`
    padding-top: 24px;
    ${FONT_16};
    color: ${STYLE_COLOR.WHITE01};
    ${STYLE_KEEP_WORD};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
      color: ${STYLE_COLOR.BLACK02};
    }
  `,
  Button: styled(AutoLinkText)`
    ${STYLE_ARROW_BUTTON_WHITE};
    padding-top: 30px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
      ${STYLE_ARROW_BUTTON_BLACK};
    }
  `,
}

export default Grid1FullImage
