import React, { Children, PropsWithChildren, ReactNode } from 'react'
import { itemTypeHelper } from '@/helpers/itemTypeHelper'
import { GridVerticalAlignType } from '@/enums/Grid'
import { STYLE_BREAKPOINT } from '@/styles/variables'
import { ContentRowType } from '@/enums/Content'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

interface GridRawProps {
  className?: string
  spacing?: boolean
  fluid?: boolean
  stretch?: boolean
  verticalAlign?: GridVerticalAlignType
  gridType?: undefined
  inverted?: boolean
  staticCell?: boolean
}

interface GridConverterProps {
  // ContentConverter 활용을 위한 properties
  gridType: string | number // GridType 통해, spacing/fluid 여부 결정
  spacing?: undefined // spacing 을 임의로 추가할 수 있게 하려면 필요
  fluid?: undefined // 화면 안쪽 padding 을 강제로 없애려면 필요
  stretch?: boolean // 각각의 cell 높이를 꽉차게 설정
  verticalAlign?: GridVerticalAlignType // 각각의 cell 내부 세로 정렬
  inverted?: boolean // 반대로 나와야 하는 지
  className?: string
  staticCell?: boolean
}

type GridProps = GridConverterProps | GridRawProps

interface GridStyledOptionalProps {
  gridType: string | number
  hasSpacing: boolean
  isFluid: boolean
  isStretchCell: boolean
  inverted: boolean
  verticalAlign: GridVerticalAlignType
  staticCell: boolean
}

type GridStyledProps = Partial<GridStyledOptionalProps>

function Grid({
  gridType,
  verticalAlign = GridVerticalAlignType.MIDDLE,
  spacing = false,
  fluid = false,
  inverted = false,
  stretch = false,
  staticCell = false,
  children,
  className,
}: PropsWithChildren<GridProps>) {
  const { isFluid, hasSpacing } = itemTypeHelper(gridType)
  const renderTableRow = (child: ReactNode): ReactNode => {
    return (
      <Styled.Cell
        hasSpacing={hasSpacing || spacing}
        verticalAlign={verticalAlign}
        inverted={inverted}
        isStretchCell={stretch}
        staticCell={!!staticCell}
      >
        {child}
      </Styled.Cell>
    )
  }

  return (
    <Styled.Wrap className={className} isFluid={isFluid || fluid}>
      <Styled.Table
        inverted={inverted}
        isStretchCell={stretch}
        gridType={gridType}
      >
        <Styled.Row gridType={gridType}>
          {Children.map(children, renderTableRow)}
        </Styled.Row>
      </Styled.Table>
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled.div<GridStyledProps>`
    box-sizing: border-box;
    max-width: 1920px;
    min-width: 1200px;
    height: 100%;
    min-height: 100%;
    margin: 0 auto;
    padding: ${({ isFluid }) => !isFluid && '0 5%'};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      min-width: 0;
      min-height: 0;
    }
  `,
  Table: styled.div<GridStyledProps>`
    width: 100%;
    min-height: ${({ isStretchCell }) => isStretchCell && '100%'};
    box-sizing: border-box;

    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      display: table;
      table-layout: fixed;
      direction: ${({ inverted }) => inverted && 'rtl'};
    }

    ${({ gridType }) => {
      switch (gridType) {
        case ContentRowType.GRID2_BACKGROUND_IMAGE_WITH_TEXT:
        case ContentRowType.GRID2_INVERTED_BACKGROUND_IMAGE_WITH_TEXT:
        case ContentRowType.GRID2_VIDEO_CONTENTS_WITH_TEXT:
          return css`
            padding: 0 5%;
          `
      }

      return ''
    }}
  `,
  Row: styled.div<GridStyledProps>`
    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      display: table-row;
    }
  `,
  Cell: styled.div<GridStyledProps>`
    height: ${({ isStretchCell }) => isStretchCell && '100%'};
    padding: ${({ hasSpacing }) => hasSpacing && '0 6px'};
    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      display: table-cell;
      vertical-align: ${({ verticalAlign }) => verticalAlign};
      direction: ${({ inverted }) => inverted && 'ltr'};
      position: ${({ staticCell }) => {
        return !staticCell ? 'static' : 'relative'
      }};
    }
  `,
}

export default Grid
