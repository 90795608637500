import React from 'react'
import Head from 'next/head'
import { ContentRow } from '@/types/Content'
import elementItemHelper from '@/helpers/elementItemHelper'

interface Grid0SpacingProps {
  item: ContentRow
}

function Grid0Meta({ item }: Grid0SpacingProps) {
  const { columns } = item
  const [{ items }] = columns ?? [{}]
  const { meta } = elementItemHelper(items)

  const metaData = meta[0].metaData

  const parseUrl = (url: string) => {
    // 풀 Url인 경우 그대로 리턴
    if (
      url.startsWith('http://') ||
      url.startsWith('https://') ||
      url.startsWith('//')
    ) {
      return url
    }

    // pathname만 있는 경우엔 PUBLIC_URL과 머지
    const withoutSlashPath = url.startsWith('/') ? url.slice(1) : url

    return `${process.env.PUBLIC_URL}${withoutSlashPath}`
  }

  return (
    <Head>
      {metaData?.title && (
        <>
          <title key='title'>{metaData.title}</title>
          <meta property='og:title' key='og:title' content={metaData.title} />
          <meta
            name='twitter:title'
            key='twitter:title'
            content={metaData.title}
          />
        </>
      )}
      {metaData?.description && (
        <>
          <meta
            name='description'
            key='description'
            content={metaData.description}
          />
          <meta
            property='og:description'
            key='og:description'
            content={metaData.description}
          />
          <meta
            name='twitter:description'
            key='twitter:description'
            content={metaData.description}
          />
        </>
      )}
      {metaData?.keywords && (
        <meta name='keywords' key='keywords' content={metaData.keywords} />
      )}
      {metaData?.url && (
        <>
          <meta
            property='og:url'
            key='og:url'
            content={parseUrl(metaData.url)}
          />
          <meta
            name='twitter:url'
            key='twitter:url'
            content={parseUrl(metaData.url)}
          />
        </>
      )}
      {metaData?.canonicalUrl && (
        <link
          rel='canonical'
          key='canonical'
          href={parseUrl(metaData.canonicalUrl)}
        />
      )}
      {metaData?.image && (
        <>
          <meta property='og:image' key='og:image' content={metaData.image} />
          <meta
            name='twitter:image'
            key='twitter:image'
            content={metaData.image}
          />
        </>
      )}
    </Head>
  )
}

export default Grid0Meta
